import {
  VTPConfiguration,
  ConfigurationContainer,
  ConfigurationServiceSubscribers,
  ConfigurationServiceSubscribersCallback,
} from '../index';
import { FeatureServices } from '@feature-hub/core';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';

export interface ConfigurationServiceV1 {
  getConfiguration: () => VTPConfiguration | null;
  setConfiguration: (configuration: VTPConfiguration) => void;
  subscribeConfiguration: (callback: ConfigurationServiceSubscribersCallback) => void;
  unsubscribe: (callback: ConfigurationServiceSubscribersCallback) => void;
}

export class ConfigurationServiceV1Implementation implements ConfigurationServiceV1 {
  constructor(
    private consumerId: string,
    private subscribers: ConfigurationServiceSubscribers,
    private configContainer: ConfigurationContainer,
    private featureServices: FeatureServices
  ) {
    this.ssrRestoreConfiguration();
  }

  public setConfiguration(configuration: VTPConfiguration) {
    this.configContainer.config = configuration;
    this.ssrSaveConfiguration();

    Object.entries(this.subscribers).forEach(([, callbacks]) => {
      callbacks.forEach((callback) => {
        callback(this.configContainer.config!);
      });
    });
  }

  private ssrSaveConfiguration() {
    const asyncSsrManager = this.featureServices['s2:async-ssr-manager'] as AsyncSsrManagerV1;
    const serializedStateManager = this.featureServices[
      's2:serialized-state-manager'
    ] as SerializedStateManagerV1;

    if (asyncSsrManager && serializedStateManager) {
      const serializedState: ConfigurationContainer = { ...this.configContainer };
      serializedStateManager.register(() => JSON.stringify(serializedState));
    }
  }

  private ssrRestoreConfiguration() {
    const asyncSsrManager = this.featureServices['s2:async-ssr-manager'] as AsyncSsrManagerV1;
    const serializedStateManager = this.featureServices[
      's2:serialized-state-manager'
    ] as SerializedStateManagerV1;

    if (!asyncSsrManager && serializedStateManager) {
      const serializedStateJSON = serializedStateManager.getSerializedState();
      const serializedSate: ConfigurationContainer | undefined = serializedStateJSON
        ? JSON.parse(serializedStateJSON)
        : undefined;
      if (serializedSate) {
        this.configContainer.config = serializedSate.config;
      }
    }
  }

  public subscribeConfiguration(callback: ConfigurationServiceSubscribersCallback) {
    if (Array.isArray(this.subscribers[this.consumerId])) {
      this.subscribers[this.consumerId] = [...this.subscribers[this.consumerId], callback];
    } else {
      this.subscribers[this.consumerId] = [callback];
    }
    if (this.configContainer.config) {
      callback(this.configContainer.config);
    }
    return () => {
      this.unsubscribe(callback);
    };
  }

  unsubscribe(callback: ConfigurationServiceSubscribersCallback) {
    this.subscribers[this.consumerId] = this.subscribers[this.consumerId].filter(
      (sub) => sub !== callback
    );
  }

  public getConfiguration() {
    return this.configContainer.config;
  }
}
